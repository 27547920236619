console.log('ECUBE project2');

// Swiperをインポート
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

//Swiper.use([Thumbs]);

document.addEventListener('DOMContentLoaded', () => {
    /*
    * 変数
    */

    // 上部へ戻るボタン
    const toTopButton = document.querySelector('.to_top');

    const thumbSliderElement = document.querySelector('.project__thumbnail');
    const thumbSwiper = new Swiper(thumbSliderElement, {
        slidesPerView: "auto",
    });

    const mainSliderElement = document.querySelector('.project__slider');
    new Swiper(mainSliderElement, {
        //modules: [Thumbs, EffectFade, Autoplay],
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        speed: 400,
        thumbs: {
            swiper: thumbSwiper,
        },
    });


    // スクロールイベントを監視
    /*
    window.addEventListener('scroll', () => {
        // 1画面分スクロールしたら表示
        if (window.scrollY > window.innerHeight) {
            if (!(bannerCloseButton && bannerCloseButton.offsetParent !== null)) {
                toTopButton.classList.add('active');
            }
        } else {
            toTopButton.classList.remove('active');
        }
    });
    */

    /*
    ** ハンバーガーメニュー
    */

    const headerMenu = document.querySelector('.header__menu');
    //const bgDom = document.querySelector('.m-background');
    //const logoDom = document.querySelector('.header__logo');
    const htmlElement = document.documentElement; // <html>要素を取得

    //.header__menuのクリックイベントを設定
    headerMenu.addEventListener('click', () => {
        htmlElement.classList.toggle('nav-open');
        //bgDom.classList.toggle('nav-open');
        //logoDom.classList.toggle('nav-open');
    });

    /*
    ** 上部に戻る
    */

    // クリックイベントを追加
    /*
    toTopButton.addEventListener('click', function () {
        // スムーズスクロールで最上部へ移動
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });
    */

    /*
    ** アンカーリンク
    */

    // ページ内アンカーリンクのスムーズスクロール処理
    /*
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            // 対象リンクのハッシュ部分を取得
            const targetId = this.getAttribute('href');

            // ページ最上部に戻るボタンの場合は別の処理で対応
            if (targetId === '#top' || targetId === '#') {
                return; // `#top` などのリンクの場合は無視して既存の処理に任せる
            }

            // デフォルトのジャンプ処理を無効にする
            e.preventDefault();

            // 特殊文字を含むIDをエスケープし、先頭のバックスラッシュを削除
            let escapedTargetId = CSS.escape(targetId);
            if (escapedTargetId.startsWith('\\#')) {
                escapedTargetId = `#${escapedTargetId.slice(2)}`; // 先頭のバックスラッシュを削除
            }

            // スクロール対象の要素を取得
            const targetElement = document.querySelector(escapedTargetId);

            if (targetElement) {
                // スムーズスクロールでターゲット要素に移動
                window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: 'smooth'
                });
            }
        });
    });
    */

});

